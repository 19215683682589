import Button from '@components/shared/Button'
import tradeboxStore from '@store/tradeboxStore'
import clsx from 'clsx'
import { ApproveTokenButton } from 'components/ApproveTokenButton/ApproveTokenButton'
import SlippagePercentageSelector from 'components/Synthetics/TradeBox/SlippagePercentageSelector'
import { getContract } from 'config/contracts'
import { DEFAULT_SLIPPAGE_AMOUNT } from 'config/factors'
import { useSyntheticsEvents } from 'context/SyntheticsEvents'
import { ExecutionFee } from 'domain/synthetics/fees'
import { useMarkets } from 'domain/synthetics/markets'
import { createDepositTxn } from 'domain/synthetics/markets/createDepositTxn'
import { createWithdrawalTxn } from 'domain/synthetics/markets/createWithdrawalTxn'
import {
  getNeedTokenApprove,
  getTokenData,
  useTokensData,
} from 'domain/synthetics/tokens'
import { TokenData } from 'domain/synthetics/tokens/types'
import { useTokensAllowanceData } from 'domain/synthetics/tokens/useTokenAllowanceData'
import { GmSwapFees, SwapAmounts } from 'domain/synthetics/trade'
import { BigNumber } from 'ethers'
import { useChainId } from 'rfx/lib/chains'
import { getByKey } from 'rfx/lib/objects'
import useWallet from 'rfx/lib/wallets/useWallet'
import { PendingTransaction, usePaymaster } from 'hooks/usePaymaster'
import { uniq } from 'lodash'
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react'
import { useKey } from 'react-use'
import { GmFees } from '../GmFees/GmFees'
import { FaChevronLeft } from 'react-icons/fa'
import { expandDecimals } from '@components/trade/rfx/tradingview/lib/numbers'
import useTokenPrice from 'hooks/rfx/usePyth/useTokenPrice'

type Props = {
  selectedOutput: string
  isVisible: boolean
  marketToken?: TokenData
  longToken?: TokenData
  shortToken?: TokenData
  marketTokenAmount: BigNumber
  marketTokenUsd: BigNumber
  longTokenAmount?: BigNumber
  longTokenUsd?: BigNumber
  shortTokenAmount?: BigNumber
  shortTokenUsd?: BigNumber
  fees?: GmSwapFees
  error?: string
  isDeposit: boolean
  executionFee?: ExecutionFee
  isHighPriceImpact: boolean
  isHighPriceImpactAccepted: boolean
  setIsHighPriceImpactAccepted: (value: boolean) => void
  onSubmitted: () => void
  onClose: () => void
  setPendingTxns: (txns: PendingTransaction[]) => void
  shouldDisableValidation?: boolean
  payComponent?: ReactNode
  receiveComponent?: ReactNode
  pairComponent?: ReactNode
  setStage?: Dispatch<
    SetStateAction<'confirmation' | 'processing' | 'swap' | undefined>
  >
  toToken: TokenData | undefined
  swapAmounts: SwapAmounts | undefined
}

export function GmConfirmationBox({
  selectedOutput,
  isVisible,
  marketToken,
  longToken,
  shortToken,
  marketTokenAmount,
  longTokenAmount,
  shortTokenAmount,
  fees,
  error,
  isDeposit,
  executionFee,
  setPendingTxns,
  shouldDisableValidation,
  payComponent,
  receiveComponent,
  pairComponent,
  setStage,
  longTokenUsd,
  shortTokenUsd,
  toToken,
  swapAmounts,
}: Props) {
  const { signer, account } = useWallet()
  const { chainId } = useChainId()
  const { marketsData } = useMarkets()
  const { tokensData, pricesUpdatedAt } = useTokensData()
  const { setPendingDeposit, setPendingWithdrawal } = useSyntheticsEvents()

  const longTokenPrice = useTokenPrice(
    longToken?.pythId || '',
    longToken?.address || '',
    'maxPrice',
  )
  const shortTokenPrice = useTokenPrice(
    shortToken?.pythId || '',
    shortToken?.address || '',
    'maxPrice',
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const market = getByKey(marketsData, marketToken?.address)
  const { sendPaymasterTransaction } = usePaymaster()

  const routerAddress = getContract(chainId, 'SyntheticsRouter')

  const slippage = tradeboxStore((store) => store.slippage)
  const updateProp = tradeboxStore((store) => store.updateProp)
  const payTokenAddresses = useMemo(() => {
    if (!marketToken) {
      return []
    }

    const addresses: string[] = []

    if (isDeposit) {
      if (longTokenAmount?.gt(0) && longToken) {
        addresses.push(longToken.address)
      }
      if (shortTokenAmount?.gt(0) && shortToken) {
        addresses.push(shortToken.address)
      }
    } else {
      addresses.push(marketToken.address)
    }

    return uniq(addresses)
  }, [
    isDeposit,
    longToken,
    longTokenAmount,
    marketToken,
    shortToken,
    shortTokenAmount,
  ])

  const { tokensAllowanceData: swapTokenAllowance } = useTokensAllowanceData(
    chainId,
    {
      spenderAddress: getContract(chainId, 'SyntheticsRouter'),
      tokenAddresses: toToken ? [toToken.address] : [],
    },
  )

  const payAmount =
    selectedOutput === longToken?.symbol ? longTokenAmount : shortTokenAmount

  const needPayTokenApproval =
    swapTokenAllowance &&
    toToken &&
    payAmount &&
    getNeedTokenApprove(swapTokenAllowance, toToken.address, payAmount)

  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    spenderAddress: routerAddress,
    tokenAddresses: payTokenAddresses,
    skip: !isVisible,
  })

  const tokensToApprove = (function getTokensToApprove() {
    const addresses: string[] = []

    if (!tokensAllowanceData) {
      return addresses
    }

    if (needPayTokenApproval) {
      addresses.push(toToken.address)
    }

    if (isDeposit) {
      if (
        longTokenAmount?.gt(0) &&
        longToken &&
        getNeedTokenApprove(
          tokensAllowanceData,
          longToken?.address,
          longTokenAmount,
        )
      ) {
        addresses.push(longToken.address)
      }

      if (
        shortTokenAmount?.gt(0) &&
        shortToken &&
        getNeedTokenApprove(
          tokensAllowanceData,
          shortToken?.address,
          shortTokenAmount,
        )
      ) {
        addresses.push(shortToken.address)
      }
    } else {
      if (
        marketTokenAmount.gt(0) &&
        marketToken &&
        getNeedTokenApprove(
          tokensAllowanceData,
          marketToken.address,
          marketTokenAmount,
        )
      ) {
        addresses.push(marketToken.address)
      }
    }

    return uniq(addresses)
  })()

  const isAllowanceLoaded = Boolean(tokensAllowanceData)

  const submitButtonState = (function getSubmitButtonState() {
    if (payTokenAddresses.length > 0 && !isAllowanceLoaded) {
      return {
        text: `Loading...`,
        disabled: true,
      }
    }

    const onSubmit = () => {
      setIsSubmitting(true)

      let txnPromise: Promise<void>

      if (isDeposit) {
        txnPromise = onCreateDeposit()
      } else {
        txnPromise = onCreateWithdrawal()
      }

      txnPromise
        .then(() => {
          // onSubmitted()
        })
        .finally(() => {
          setStage && setStage('swap')
          setIsSubmitting(false)
        })
    }

    if (error) {
      return {
        text: error,
        disabled: !shouldDisableValidation,
        onClick: onSubmit,
      }
    }

    if (isSubmitting) {
      return {
        text: isDeposit ? `Buying RP...` : `Selling RP...`,
        disabled: true,
      }
    }

    if (tokensToApprove.length > 0 && marketToken) {
      const symbols = tokensToApprove.map((address) => {
        const token = getTokenData(tokensData, address)!
        return address === marketToken.address
          ? 'RP'
          : token?.assetSymbol ?? token?.symbol
      })

      const symbolsText = symbols.join(', ')

      return {
        text: `Pending ${symbolsText} approval`,
        disabled: true,
      }
    }

    const operationText = isDeposit ? `Buy` : `Sell`
    const text = `Confirm ${operationText}`

    return {
      text,
      onClick: onSubmit,
    }
  })()

  useKey(
    'Enter',
    () => {
      if (
        isVisible &&
        submitButtonState.onClick &&
        !submitButtonState.disabled
      ) {
        submitButtonState.onClick()
      }
    },
    {},
    [isVisible, submitButtonState],
  )

  function onCreateDeposit() {
    if (
      !account ||
      !executionFee ||
      !marketToken ||
      !market ||
      !marketTokenAmount ||
      !tokensData ||
      !signer
    ) {
      return Promise.resolve()
    }

    return createDepositTxn(chainId, signer, {
      account,
      initialLongTokenAddress: longToken?.address || market.longTokenAddress,
      initialShortTokenAddress: shortToken?.address || market.shortTokenAddress,
      longTokenSwapPath: [],
      shortTokenSwapPath: [],
      longTokenAmount: longTokenAmount || BigNumber.from(0),
      shortTokenAmount: shortTokenAmount || BigNumber.from(0),
      marketTokenAddress: marketToken.address,
      minMarketTokens: marketTokenAmount,
      executionFee: executionFee.feeTokenAmount,
      allowedSlippage: slippage || DEFAULT_SLIPPAGE_AMOUNT,
      skipSimulation: true,
      tokensData,
      setPendingTxns,
      setPendingDeposit,
      pricesUpdatedAt,
      sendPaymasterTransaction,
    })
  }

  function onCreateWithdrawal() {
    if (
      !account ||
      !market ||
      !marketToken ||
      !executionFee ||
      !longTokenAmount ||
      !shortTokenAmount ||
      !tokensData ||
      longTokenPrice.eq(0) ||
      !signer ||
      !shortTokenUsd ||
      !longTokenUsd ||
      !longToken ||
      !shortToken
    ) {
      return Promise.resolve()
    }

    const _minLongTokenAmount =
      selectedOutput === 'BOTH'
        ? longTokenAmount
        : toToken?.symbol === shortToken.symbol
          ? longTokenUsd
              .mul(expandDecimals(1, shortToken.decimals))
              .div(shortTokenPrice)
          : longTokenAmount
    const _minShortTokenAmount =
      selectedOutput === 'BOTH'
        ? shortTokenAmount
        : toToken?.symbol !== shortToken.symbol
          ? shortTokenUsd
              .mul(expandDecimals(1, longToken.decimals))
              .div(longTokenPrice)
          : shortTokenAmount

    return createWithdrawalTxn(chainId, signer, sendPaymasterTransaction, {
      account,
      initialLongTokenAddress: longToken?.address || market.longTokenAddress,
      initialShortTokenAddress: shortToken?.address || market.shortTokenAddress,
      longTokenSwapPath:
        selectedOutput === 'BOTH'
          ? []
          : toToken?.symbol === shortToken?.symbol
            ? swapAmounts?.swapPathStats?.swapPath || []
            : [],
      shortTokenSwapPath:
        selectedOutput === 'BOTH'
          ? []
          : toToken?.symbol === longToken?.symbol
            ? swapAmounts?.swapPathStats?.swapPath || []
            : [],
      marketTokenAmount: marketTokenAmount,
      minLongTokenAmount: _minLongTokenAmount,
      minShortTokenAmount: _minShortTokenAmount,
      marketTokenAddress: marketToken.address,
      executionFee: executionFee.feeTokenAmount,
      allowedSlippage: slippage || DEFAULT_SLIPPAGE_AMOUNT,
      tokensData,
      skipSimulation: false,
      setPendingTxns,
      setPendingWithdrawal,
      pricesUpdatedAt,
    })
  }

  const btnColor =
    payTokenAddresses.length > 0 && !isAllowanceLoaded
      ? ''
      : isDeposit
        ? 'bg-th-success'
        : 'bg-th-error'

  const handleSlippagePercentageChange = (val: number) => {
    updateProp('slippage', val)
  }

  return (
    <div className="flex h-full flex-col pt-1">
      <div className="Confirmation-box-main">
        <div className="flex justify-between pb-4">
          <span
            className="cursor-pointer"
            onClick={() => setStage && setStage('swap')}
          >
            <FaChevronLeft fontSize={16} color="#909096" />
          </span>
        </div>
      </div>
      <div className="mb-4">
        {isDeposit ? (
          <div className="">
            <div>{pairComponent}</div>
            <div>{payComponent}</div>
            <div>{receiveComponent}</div>
          </div>
        ) : (
          <>
            <div>{receiveComponent}</div>
            <div>{payComponent}</div>
            <div>{pairComponent}</div>
          </>
        )}
      </div>

      <div className="mb-4 space-y-2">
        <p className="text-xs font-medium text-th-fgd-3">Slippage</p>
        <SlippagePercentageSelector
          slippagePercentage={slippage}
          changeSlippagePercentage={handleSlippagePercentageChange}
        />
      </div>

      <GmFees
        isDeposit={isDeposit}
        totalFees={fees?.totalFees}
        swapFee={fees?.swapFee}
        swapPriceImpact={fees?.swapPriceImpact}
        executionFee={executionFee}
      />

      <div className="mt-4 flex flex-1 items-end">
        {tokensToApprove && tokensToApprove.length > 0 ? (
          <div className="w-full">
            {tokensToApprove.map((address) => {
              const token = getTokenData(tokensData, address)!
              return (
                <div key={address}>
                  <ApproveTokenButton
                    key={address}
                    tokenAddress={address}
                    tokenSymbol={
                      address === marketToken?.address
                        ? 'RP'
                        : token.assetSymbol ?? token.symbol
                    }
                    spenderAddress={routerAddress}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <Button
            className={clsx('w-full border-none text-sm uppercase', btnColor)}
            type="submit"
            secondary={btnColor !== ''}
            onClick={submitButtonState.onClick}
            disabled={submitButtonState.disabled}
            size="large"
          >
            {submitButtonState.text}
          </Button>
        )}
      </div>
    </div>
  )
}
